<template>
	<div class="o-signatures-new block block--notitle" :class="interface.visibility" v-if="!inMaintenance(config, 'e-signatures') && !clapActivated">
		<div class="equal-padding">
			<button class="button button--secondary o-signatures-new__create" @click="newSignature" :class="{'button--disabled': left <= 1}">Signer un nouveau document</button>
			<div class="o-signatures-new__infos">
				<div class="o-signatures-new__left">
					<div class="subtitle">Signatures restantes</div>
					<div class="title-big">
						<loader v-if="loading===true"></loader><span v-else>{{ left }}</span>
					</div>
				</div>
				<div class="bottom-right" v-if="hasRole(user,'company')">
					<button class="button" data-icon_after="plus" @click="buy()" :class="{'button--secondary': left === 0}">Acheter des signatures</button>
				</div>
			</div>
		</div>
	</div>

</template>

<script>
	import signatureRepository from '@/repositories/signatureRepository';

	export default {
		name: "signatures-new",
		props: ['data','interface'],
		data(){
			return{
				left: 0,
				loading: true
			}
		},
		methods:{
			newSignature(){
				this.$popin('signature-add', false, true, 1)
			},
			buy(){
				this.$popin('signature-buy')
			},
			getStock(){

				signatureRepository.getStock().then(count=>{
					this.left = count;
					this.loading = false
				})
			}
		},
    computed:{
      config(){ return this.$config() },
      user(){ return this.$user() },
      clapActivated(){ return this.user.clap_token ? true : false }
    },
    mounted() {

			this.$update(this.getStock);
			this.getStock();
		}
	}
</script>

<style lang="scss">
	@import '../../environment';

	.o-signatures-new{
		margin-top: $space;
		&__create{
			width: 100%;
			& + *{ margin-top: 3rem }
		}

		&__infos{ background: $c-background; padding: $space-m  }
		&__left{
			display: flex; justify-content: space-between; align-items: center;
			& > .title-big{ position: relative }
		}
	}
</style>