import Vue from "vue";
import cache from "@/services/cache";
import store from "@/services/store";

export default {

	get(id) {
		return new Promise((resolve, reject) => {
			Vue.http.get(id ? ('contact/' + id) : 'contact' ).then(response => {
				resolve(response.body.response)
			}, reject)
		});
	},

	update(id, data) {

		return new Promise((resolve, reject) => {
			Vue.http.post('contact/'+id, data).then(response => {
				resolve(response.body.response)
			}, reject)
		});
	},

	requestElearningPassword(id) {

		return Vue.http.get('contact/'+id+'/elearning/password')
	},

	resetElearningPassword(id, data) {

		return Vue.http.post('contact/'+id+'/elearning/password', data)
	},

	updateAddress(id, data) {

		return Vue.http.post('contact/address/'+id, data)
	},

	create(data) {
		return new Promise((resolve, reject) => {
			Vue.http.post('contact', data).then(response => {
				resolve(response.body.response)
			}, reject)
		});
	},

	findExperts(data) {

		return cache.list('contact/expert', data)
	},

	search(params) {

		return new Promise((resolve, reject) => {

			Vue.http.get('contact/search', {params:params}).then(response => {

				resolve(response.body.response);

			},reject);
		});
	},

	company(data){
		return new Promise((resolve, reject) => {
			Vue.http.post('contact/company', data).then(response=>{
				resolve(response.body.response);
			}, reject)
		});
	},

	refresh(){
		return Vue.http.post('contact/refresh')
	},

	getContract(data){
		return Vue.http.get('contact/contract')
	},

	addContract(data){

		return new Promise((resolve, reject) => {

			Vue.http.post('contact/contract', data).then(response => {

				resolve(response.body.response);

			},reject);
		});	},

	quote(){

		return new Promise((resolve, reject) => {

			Vue.http.get('contact/contract/quote').then(response => {

				resolve(response.body.response);

			},reject);
		});
	},

	sendAsserisCode(){

		return new Promise((resolve, reject) => {

			Vue.http.get('contact/contract/asseris').then(response => {

				resolve(response.body.response);

			},reject);
		});
	},

	checkAsserisCode(code){

		return new Promise((resolve, reject) => {

			Vue.http.post('contact/contract/asseris', {code:code}).then(response => {

				resolve(response.body.response);

			},reject);
		});
	},

	setContractAgencies(){

		return new Promise((resolve, reject) => {

			Vue.http.post('contact/contract/agencies').then(response => {

				resolve(response.body.response);

			},reject);
		});
	},

	sendCaciCode(){

		return new Promise((resolve, reject) => {

			Vue.http.get('contact/contract/caci').then(response => {

				resolve(response.body.response);

			},reject);
		});
	},

	checkCaciCode(code){

		return new Promise((resolve, reject) => {

			Vue.http.post('contact/contract/caci', {code:code}).then(response => {

				resolve(response.body.response);

			},reject);
		});
	},

	// checkup() {
	// 	return new Promise((resolve, reject) => {
	// 		Vue.http.get('contact/checkup').then(response => {
	// 			resolve(response.body.response)
	// 		}, reject)
	// 	});
	// }
};
