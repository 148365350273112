<template>
  <div class="m-contact-add">
    <h2 class="title">Ajouter un collaborateur</h2>

    <form @submit.prevent="addContact" ref="form" id="add-contact">
      <vue-form-generator tag="div" :schema="typeForm" :model="params" :options="formOptions" class="m-contact-add__form"></vue-form-generator>
      <div class="m-contact-add__collab form-group valid fields-label field-input" v-if="params.position && params.position === 'Agent commercial' && params.id">
        <label><span>Collaborateur</span></label>
        {{params.firstname}} {{ params.lastname}}
      </div>
      <div class="m-contact-add__collab" v-if="(params.position && params.position !== 'Agent commercial') || params.id || !search">
        <vue-form-generator tag="div" :schema="params.id ? formCommercialAgent : form" :model="params" :options="formOptions" class="m-contact-add__form"></vue-form-generator>
        <div class="bottom-center">
          <submit class="button--secondary" text="Enregistrer" text-success="Enregistré" :loading="loading" :success="sent"></submit>
        </div>
      </div>
    </form>

    <form @submit.prevent="searchContact" v-if="params.position === 'Agent commercial' && !params.id && search" id="add-contact_manual">
      <vue-form-generator tag="div" :schema="searchForm" :model="params" :options="formOptions" class="m-contact-add__form"></vue-form-generator>
      <div class="bottom-right">
        <a class="button" @click="search=false" v-if="!result">Ajouter manuellement</a>
        <submit class="button--secondary" text="Rechercher" text-success="Enregistré" :loading="loading" :success="sent"></submit>
      </div>
    </form>
  </div>
</template>

<script>
import contactRepository from "@/repositories/contactRepository";
import companyRepository from "@/repositories/companyRepository";

export default {
  name: "contact-add",
  props:['data'],
  data(){

    let self = this;

    return{
      search: true,
      loading: false,
      sent: false,
      result: true,
      params:{
        id:false,
        civility:'',
        firstname:'',
        lastname:'',
        addresses:{
          home:{
            id:false,
            isHome:true,
            isActive:true,
            email:'',
            phone:'',
          },
          work:{
            id:false,
            isActive:true,
            email:'',
            position:'',
            street:'',
            zip:'',
            city:'',
	          issuedAt: ''
          }
        },
        birthday:'',
        legalForm:''
      },
      formOptions: {
        validateAfterLoad: true,
        validateAfterChanged: true,
        validateAsync: true
      },
      searchForm:{
        fields:[
          {
            type: "input",
            inputType: "email",
            inputName: "email",
	          maxlength: 60,
            model: "addresses.home.email",
            styleClasses: "fields-label",
            label: "Email personnel du collaborateur"
          },
        ]
      },
      typeForm:{
        fields:[
          {
            type: "select",
            inputName: "addresses[1][position]",
            model: "position",
            styleClasses: "fields-label",
            label: "Statut",
            selectOptions:{
              noneSelectedText: "---"
            },
            values(){
              return [
                { id: "Négociateur immobilier", name: 'Négociateur immobilier (salarié)'},
                { id: "Agent commercial", name: 'Agent commercial (non salarié)'},
                { id: "Autre collaborateur", name: 'Autre collaborateur'}
              ]
            }
          },
        ]
      },
      form:{
        groups:[
          {
            legend: "Informations personnelles",
            styleClasses: "form-group-title"
          },
          {
            fields: [
              {
                type: "radios",
                inputName: "civility",
                model: "civility",
                styleClasses: ["fields-label", "radio-list-inputs", "radio-list-inputs--inline"],
                label: "Civilité",
                values: [
                  "Monsieur",
                  "Madame"
                ]
              },
              {
                type: "input",
                inputType: "text",
                inputName: "lastname",
	              maxlength: 60,
                model: "lastname",
                styleClasses: "fields-label",
                label: "Nom",
                required: true
              },
              {
                type: "input",
                inputType: "text",
                inputName: "firstname",
                model: "firstname",
	              maxlength: 60,
                styleClasses: "fields-label",
                label: "Prénom",
                required: true
              },
              {
                type: "input",
                inputType: "email",
                inputName: "addresses[0][email]",
	              maxlength: 60,
                model: "addresses.home.email",
                styleClasses: "fields-label",
                label: "Email personnel",
                required: true
              },
                // HERE
              {
                type: "input",
                inputType: "email",
                inputName: "addresses[1][email]",
                maxlength: 60,
                model: "addresses.work.email",
                styleClasses: "fields-label",
                label: "Email professionnel",
                required: true
              },
                //
              {
                type: "calendar-custom",
                inputName: "birthday",
                model: "birthday",
                styleClasses: "fields-label",
                label: "Date de naissance",
                min: '1920-01-01',
                max: this.getDateFromYear(18, true),
                default : this.getDateFromYear(18, false)
              },
              {
                type: "input",
                inputType: "text",
                inputName: "addresses[0][street]",
                model: "addresses.home.street",
	              maxlength: 60,
                styleClasses: "fields-label",
                label: "N° / Voie",
                required: true
              },
              {
                type: "input",
                inputType: "hidden",
                inputName: "addresses[0][isHome]",
                model: "addresses.home.isHome"
              },
              {
                type: "input",
                inputType: "hidden",
                inputName: "addresses[0][isActive]",
                model: "addresses.home.isActive"
              },
              {
                type: "input",
                inputType: "text",
                inputName: "addresses[0][zip]",
                model: "addresses.home.zip",
                styleClasses: "fields-label",
                label: "Code postal",
                required: true
              },
              {
                type: "input",
                inputType: "text",
                inputName: "addresses[0][city]",
                model: "addresses.home.city",
	              maxlength: 50,
                styleClasses: "fields-label",
                label: "Ville",
                required: true
              },
              {
                type: "upload",
                inputName: "avatar",
                styleClasses: "fields-label",
                label: "Avatar",
                accept: 'image/*'
              }
            ]
          },
          {
            legend: "Informations professionnelles",
            styleClasses: "form-group-title"
          },
          {
            fields: [
              {
                type: "calendar-custom",
                inputName: "addresses[1][issuedAt]",
                model: "addresses.work.issuedAt",
                styleClasses: "fields-label",
                label: "Date de délivrance de l'attestation d'habilitation",
                min: '1920-01-01',
                max: this.now(),
                required: true,
              },
              {
                type: "tel-custom",
                inputName: "addresses[1][phone]",
                model: "addresses.work.phone",
                styleClasses: "fields-label",
                label: "Téléphone pro"
              },
              {
                type: "input",
                inputType: "hidden",
                inputName: "addresses[1][isActive]",
                model: "addresses.work.isActive"
              },
              {
                type: "input",
                inputType: "email",
                inputName: "addresses[1][email]",
	              maxlength: 60,
                model: "addresses.work.email",
                styleClasses: "fields-label",
                label: "Email pro",
                required: true
              },
	            {
		            type: "checkbox-custom",
		            inputName: "inviteCollaborator",
		            model: "inviteCollaborator",
		            styleClasses: "fields-label",
		            label: "Inviter le collaborateur"
	            },
              {
                type: "input",
                inputType: "text",
                inputName: "rsac",
                model: "rsac",
                styleClasses: "fields-label",
                label: "Rsac",
                visible() {
                  return self.params.position === 'Agent commercial'
                }
              },
              {
                type: "select",
                inputName: "legalForm",
                model: "legalForm",
                styleClasses: "fields-label",
                label: "Forme Juridique",
                selectOptions: {
                  noneSelectedText: "---"
                },
                values: [
                  'EI', 'Micro Entrepreneur', 'EIRL', 'Micro Entrepreneur + EIRL'
                ],
                visible() {
                  return self.params.position === 'Agent commercial'
                }
              }
            ]
          }
        ]
      },
      formCommercialAgent:{
        fields:[
          {
            type: "tel-custom",
            inputName: "phone",
            model: "addresses.work.phone",
            styleClasses: "fields-label",
            label: "Téléphone pro"
          },
          {
            type: "input",
            inputType: "hidden",
            inputName: "isActive",
            model: "addresses.work.isActive"
          },
          {
            type: "input",
            inputType: "email",
            inputName: "email",
	          maxlength: 60,
            model: "addresses.work.email",
            styleClasses: "fields-label",
            label: "Email pro",
            required: true
          },
          {
            type: "calendar-custom",
            inputName: "issuedAt",
            model: "addresses.work.issuedAt",
            styleClasses: "fields-label",
            label: "Date de délivrance de l'attestation d'habilitation",
            required: true
          },
        ]
      }
    }
  },
	computed:{
		user(){
			return this.$user()
		}
	},
  watch:{
    'params.position'(newValue, oldValue){

      if( this.params.id ){

        this.params.id = false;
        this.params.firstname = '';
        this.params.lastname = '';
      }
    }
  },
  methods:{
    done(){

      this.loading = false;
      this.sent = true;

      let self = this;

      setTimeout(function () {
        self.$update()
        self.$emit('close')
      }, 1500);

    },
    searchContact(){

      this.loading = true;

      contactRepository.search({email:this.params.addresses.home.email}).then(contact => {

        this.search = false;
        this.params.id = contact.id;
        this.params.firstname = contact.firstname;
        this.params.lastname = contact.lastname;
        this.loading = false;

      }, e => {
        this.result = false;
        this.loading = false;
      })
    },
    addContact(){

      this.loading = true;

      let formData = new FormData( this.$refs.form );

      const issuedDate= this.params.addresses.work.issuedAt;
      const birthday= this.params.birthday;



      if(issuedDate && issuedDate !== '')
        formData.set('addresses[1][issuedAt]', this.formatDigitDateApi(issuedDate));


      if(birthday && birthday !== '')
        formData.set('birthday', this.formatDigitDateApi(birthday));

			if(this.user.canCreateAccount && formData.get('inviteCollaborator') === 'on')
				formData.set('hasDashboard', true);


      if( this.params.id ){

        formData.set('position', formData.get('addresses[1][position]'));
        formData.delete('addresses[1][position]');

        companyRepository.associate(this.params.id, formData).then(this.done, e => {
          this.loading = false;
        })

      }else{
        contactRepository.create(formData).then(this.done, e => {
          this.loading = false;
        }).catch(e => {
          this.loading = false;
        })
      }
    }
  }
}
</script>

<style lang="scss">
@import '../../environment';
.m-contact-add{
  &__collab{ margin-top:$space-m }
  .title{ margin-bottom: $space }
  form{ text-align: left }
  form+form{ margin-top: $space-m }
}
</style>
