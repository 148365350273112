<template>
  <div class="m-contact-add">
    <h2 class="title">Modifier un collaborateur</h2>

    <form @submit.prevent="editContact" enctype="multipart/form-data"  ref="form" id="edit-contact">
	    <div class="m-contact-add__warning" v-if="data.isLegalRepresentative">
		    Pour modifier les informations grisées, veuillez contacter le service administratif du SNPI en joignant votre pièce d'identité en cours de validité.
	    </div>
      <vue-form-generator tag="div" :schema="form" :model="data" :options="formOptions" class="m-contact-add__form"></vue-form-generator>
      <div class="bottom-right">
        <submit class="button--secondary" text="Enregistrer" text-success="Enregistré" :loading="loading" :success="sent"></submit>
      </div>
    </form>
  </div>
</template>

<script>
import contactRepository from "@/repositories/contactRepository";
import companyRepository from "@/repositories/companyRepository";

export default {
  name: "contact-edit",
  props:['data'],
  data(){

    let self = this;

    return{
      loading: false,
      sent: false,
      model: false,
      formOptions: {
        validateAfterLoad: true,
        validateAfterChanged: true,
        validateAsync: true
      },
      searchForm:{
        fields:[
          {
            type: "input",
            inputType: "email",
            inputName: "email",
	          maxlength: 60,
            model: "email",
            styleClasses: "fields-label",
            label: "Rechercher par email perso"
          },
        ]
      },
      form:{
        groups:[
          {
            legend: "Informations personnelles",
            styleClasses: "form-group-title"
          },
          {
            fields:[
              {
                type: "radios",
                inputName: "civility",
                model: "civility",
                styleClasses:["fields-label","radio-list-inputs","radio-list-inputs--inline"],
                label: "Civilité",
                values: [
                  "Monsieur",
                  "Madame"
                ],
	              disabled(){ return self.data.isLegalRepresentative }
              },
              {
                type: "input",
                inputType: "text",
                inputName: "lastname",
                model: "lastname",
	              maxlength: 60,
                styleClasses: "fields-label",
                label: "Nom",
                required: true,
	              disabled(){ return self.data.isLegalRepresentative }
              },
              {
                type: "input",
                inputType: "text",
                inputName: "firstname",
                model: "firstname",
	              maxlength: 60,
                styleClasses: "fields-label",
                label: "Prénom",
                required: true,
	              disabled(){ return self.data.isLegalRepresentative }
              },
              {
                type: "calendar-custom",
                inputName: "birthday",
                model: "birthday",
                styleClasses: "fields-label",
                label: "Date de naissance",
                min: '1920-01-01',
                max: this.getDateFromYear(18, true),
                default : this.getDateFromYear(18, false),
	              disabled(){ return self.data.isLegalRepresentative }
              },
              {
                type: "input",
                inputType: "hidden",
                inputName: "addresses["+self.data.addresses.home.index+"][isActive]",
                model: "addresses.home.isActive"
              },
              {
                type: "input",
                inputType: "text",
                inputName: "addresses["+self.data.addresses.home.index+"][street]",
                model: "addresses.home.street",
	              maxlength: 60,
                styleClasses: "fields-label",
                label: "N° / Voie",
                required: true
              },
              {
                type: "input",
                inputType: "text",
                inputName: "addresses["+self.data.addresses.home.index+"][zip]",
                model: "addresses.home.zip",
                styleClasses: "fields-label",
                label: "Code postal",
                required: true
              },
              {
                type: "input",
                inputType: "text",
                inputName: "addresses["+self.data.addresses.home.index+"][city]",
                model: "addresses.home.city",
	              maxlength: 50,
                styleClasses: "fields-label",
                label: "Ville",
                required: true
              },
              {
                type: "upload",
                inputName: "avatar",
                styleClasses: "fields-label",
                label: "Avatar",
                accept:'image/*'
              }
            ]
          },
          {
            legend: "Informations professionnelles",
            styleClasses: "form-group-title"
          },
          {
            fields: [
              {
                type: "calendar-custom",
                inputName: "addresses["+self.data.addresses.work.index+"][issuedAt]",
                model: "addresses.work.issuedAt",
                styleClasses: "fields-label",
                label: "Date de délivrance de l'attestation d'habilitation",
                required: true,
                min: '1920-01-01',
                max: this.now(),
                visible(){ return !self.data.isLegalRepresentative }
              },
              {
                type: "calendar-custom",
                inputName: "addresses["+self.data.addresses.work.index+"][startedAt]",
                model: "addresses.work.startedAt",
                styleClasses: "fields-label",
                label: "Date d'entrée dans l'entreprise",
                required: true,
                min: '1920-01-01',
                max: this.now(),
                visible(){ return self.data.isLegalRepresentative }
              },
              {
                type: "tel-custom",
                inputName: "addresses["+self.data.addresses.work.index+"][phone]",
                model: "addresses.work.phone",
                styleClasses: "fields-label",
                label: "Téléphone pro"
              },
              {
                type: "input",
                inputType: "hidden",
                inputName: "addresses["+self.data.addresses.work.index+"][isActive]",
                model: "addresses.work.isActive"
              },
              {
                type: "input",
                inputType: "email",
                inputName: "addresses["+self.data.addresses.work.index+"][email]",
                model: "addresses.work.email",
	              maxlength: 60,
                styleClasses: "fields-label",
                label: "Email pro",
                required: true
              }
            ]
          }
        ]
      }
    }
  },
  methods:{
    close(){
      this.$update()
      this.$emit('close')
    },
    editContact(){

      this.loading = true;

      let formData = new FormData( this.$refs.form );

      contactRepository.update(this.data.id,formData).then(response => {

        this.loading = false;
        this.sent = true;

        setTimeout(this.close, 1500);

      }, e => {
        this.loading = false;
      })

    }
  }
}
</script>

<style lang="scss">
@import '../../environment';
.m-contact-add{
  .title{ margin-bottom: $space }
  form{ text-align: left }
	&__warning{
		max-width: 50rem; margin-bottom: $space;
	}
}
</style>