export const Mixins = {
  methods: {
    getDays(timestamp1, timestamp2) {
      let diff = Math.abs(timestamp1 - timestamp2);
      return Math.round(diff / (1000 * 3600 * 24));
    },
    formatDate(timestamp) {
      if (timestamp === false || typeof timestamp == "undefined") return " - ";

      const date = new Date(timestamp);
      const options = {
        year: "numeric",
        month: "long",
        day: "2-digit",
        timeZone: "Europe/Paris",
      };

      return date.toLocaleDateString("fr-FR", options);
    },
    getElapsedTime(date) {
      let diff = {};
      let tmp = this.$store.getters.currentTime() - date;

      tmp = Math.floor(tmp / 1000);
      diff.sec = tmp % 60;

      tmp = Math.floor((tmp - diff.sec) / 60);
      diff.min = tmp % 60;

      tmp = Math.floor((tmp - diff.min) / 60);
      diff.hour = tmp % 24;

      tmp = Math.floor((tmp - diff.hour) / 24);
      diff.day = tmp;

      if (diff.day > 365) return "Il y a plus d'un an";
      else if (diff.day > 1) return "Il y a " + diff.day + " jours";
      else if (diff.hour > 1) return "Il y a " + diff.hour + " heures";
      else if (diff.min > 1) return "Il y a " + diff.min + " minutes";
      else return "Il y a " + diff.sec + " secondes";
    },
    formatDateTime(timestamp) {
      if (timestamp === false || typeof timestamp == "undefined") return " - ";

      const date = new Date(timestamp);
      const options = {
        year: "numeric",
        month: "long",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
        timeZone: "Europe/Paris",
      };

      return date.toLocaleDateString("fr-FR", options);
    },
    formatShortDateWithDay(timestamp) {
      if (timestamp === false || typeof timestamp == "undefined") return " - ";

      const date = new Date(timestamp);
      const options = { month: "short", day: "2-digit", weekday: "short" };

      return date.toLocaleDateString("fr-FR", options);
    },
    formatDateInput(timestamp) {
      if (timestamp === false || typeof timestamp == "undefined") return " - ";

      const date = new Date(timestamp);
      const options = {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        timeZone: "Europe/Paris",
      };

      return date.toLocaleDateString("fr-FR", options);
    },
    formatBirthday(timestamp) {
      if (timestamp === false || typeof timestamp == "undefined") return " - ";

      const date = new Date(timestamp);
      const options = {
        month: "long",
        day: "2-digit",
        timeZone: "Europe/Paris",
      };

      return date.toLocaleDateString("fr-FR", options);
    },
    getYear() {
      return new Date().getFullYear();
    },
    formatTitle(title, user) {
      if (typeof title == "string") return title;
      else if (user) return title[user.type];
      return "";
    },
    formatYear(timestamp) {
      if (timestamp === false || typeof timestamp == "undefined") return " - ";

      const date = new Date(timestamp);
      const options = { year: "numeric", timeZone: "Europe/Paris" };

      return date.toLocaleDateString("fr-FR", options);
    },
    formatShortDate(timestamp) {
      if (timestamp === false || typeof timestamp == "undefined") return " - ";

      const date = new Date(timestamp);
      const day = date.toLocaleDateString("fr-FR", {
        day: "2-digit",
        timeZone: "Europe/Paris",
      });
      const month = date.toLocaleDateString("fr-FR", {
        month: "short",
        timeZone: "Europe/Paris",
      });

      return day + ' <span class="month">' + month + "</span>";
    },
    formatDigitDate(timestamp) {
      if (timestamp === false || typeof timestamp == "undefined") return " - ";

      const date = new Date(timestamp);
      const options = {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        timeZone: "Europe/Paris",
      };

      return date.toLocaleDateString("fr-FR", options);
    },
    formatDigitDateApi(date) {
      const formattedDate = new Date(date);
      return (
        formattedDate.getFullYear() +
        "-" +
        (formattedDate.getMonth() + 1) +
        "-" +
        formattedDate.getDate()
      );
    },
    formatShortDateEvent(timestamp) {
      if (timestamp === false || typeof timestamp == "undefined") {
        return " - ";
      }

      const startDate = new Date(timestamp);
      const startDay = startDate.toLocaleDateString("en-US", {
        day: "2-digit",
      });
      const startMonth = startDate.toLocaleDateString("en-US", {
        month: "short",
      });

      return `${startMonth} ${startDay}, ${startDate.getFullYear()}`;
    },
    now() {
      const date = new Date();
      return (
        date.getFullYear() +
        "-" +
        (date.getMonth() < 9 ? "0" : "") +
        (date.getMonth() + 1) +
        "-" +
        (date.getDate() < 10 ? "0" : "") +
        date.getDate()
      );
    },
    getDateFromYear(year, asString) {
      let date = new Date();
      date.setFullYear(date.getFullYear() - year);

      if (asString)
        return (
          date.getFullYear() +
          "-" +
          (date.getMonth() < 9 ? "0" : "") +
          (date.getMonth() + 1) +
          "-" +
          (date.getDate() < 10 ? "0" : "") +
          date.getDate()
        );
      else return date;
    },
    ntobr(string) {
      return string
        ? string
            .replace(/^\s+|\s+$/g, "")
            .replace(/\n\n/g, "\n")
            .replace(/\n/g, "<br />")
        : "";
    },
    ntodash(string) {
      return string
        ? string
            .replace(/\r\n\r\n/g, "\r\n")
            .replace(/^\s+|\s+$/g, "")
            .replace(/\n\n/g, "\n")
            .replace(/\n/g, '<span class="dash"></span>')
        : "";
    },
    formatPrice(num, ceilSecondDeimal) {
      if (num === false || typeof num == "undefined") return " - ";

      num = parseFloat(num);

      if (num === 0) return "Offert!";

      if(ceilSecondDeimal) num = Math.ceil(num*100)/100;

      return num.toFixed(2).replace(/\.00$/, "") + "€";
    },
    youtubeId(url) {
      let ID = "";
      url = url
        .replace(/(>|<)/gi, "")
        .split(/(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/)/);

      if (url[2] !== undefined) {
        ID = url[2].split(/[^0-9a-z_\-]/i);
        ID = ID[0];
      } else {
        ID = url;
      }
      return ID;
    },
    vimeoId(url) {
      let regExp = /https?:\/\/(www\.)?vimeo.com\/(\d+)($|\/)/;
      let match = url.match(regExp);

      if (match) return match[2];
      else return false;
    },
    getPage(url) {
      return url.replace(/^.*\/\/[^\/]+/, "");
    },
    checkRole(user, item) {
      if (
        item.include &&
        item.include === "legal_representative" &&
        user &&
        user.type === "company"
      )
        item.include = "company";

      if (
        item.exclude &&
        item.exclude === "legal_representative" &&
        user &&
        user.type === "company"
      )
        item.exclude = "company";

      if (
        item.exclude &&
        item.exclude === "commercial_agent" &&
        user &&
        user.type === "contact"
      )
        item.exclude = "contact";

      if (
        item.include &&
        item.include === "commercial_agent" &&
        user &&
        user.type === "contact"
      )
        item.include = "contact";

      let hasPermission = true;

      if (item.permission && !user.isLegalRepresentative)
        hasPermission = this.checkPermission(item.permission, user);

      return (
        hasPermission &&
        (typeof item.role === "undefined" ||
          ((item.role === true ||
            (user.roles && user.roles.indexOf(item.role) > -1)) &&
            (!item.exclude || (user.type && user.type !== item.exclude))) ||
          (item.include && user.type && user.type === item.include)) &&
        (typeof item.has === "undefined" || user[item.has])
      );
    },
    checkPermission(permission, user, defaultAccess = true) {
      if (parseInt(process.env.VUE_APP_PERMISSIONS) === 0) return true;

      if (user) {
        let access = defaultAccess;

        if (user.isLegalRepresentative) access = true;
        else if (user.roles.some((role) => role.includes(permission)))
          access = !user.roles.includes(permission + "_HIDDEN");

        return access;
      }
    },
    hasRole(user, role) {
      return this.checkRole(user, { role: "ROLE_" + role.toUpperCase() });
    },
    inMaintenance(config, item) {
      return (
        config &&
        config.maintenance &&
        config.maintenance.enabled &&
        item in config &&
        !config[item].enabled
      );
    },
    daysFromMultico(multicoDate) {
      let multico = new Date(multicoDate);
      let today = new Date();
      return (today.getTime() - multico.getTime()) / (1000 * 3600 * 24);
    },
    formateDateMultico(multicoDate) {
      let multico = new Date(multicoDate);
      return this.formatDate(multico);
    },
    formateDateEndMultico(multicoDate) {
      let multico = new Date(multicoDate);
      return this.formatDate(multico.setDate(multico.getDate() + 60));
    },
    humanDuration(seconds) {
      let numyears = Math.floor(seconds / 31536000);
      let numdays = Math.floor((seconds % 31536000) / 86400);
      let numhours = Math.floor(((seconds % 31536000) % 86400) / 3600);
      let numminutes = Math.floor((((seconds % 31536000) % 86400) % 3600) / 60);
      let numseconds = (((seconds % 31536000) % 86400) % 3600) % 60;

      return (
        (numyears > 0 ? numyears + " année" + (numyears > 1 ? "s" : "") : "") +
        " " +
        (numdays > 0 ? numdays + " jour" + (numdays > 1 ? "s" : "") : "") +
        " " +
        (numhours > 0 ? numhours + " heure" + (numhours > 1 ? "s" : "") : "") +
        " " +
        (numminutes > 0
          ? numminutes + " minute" + (numminutes > 1 ? "s" : "")
          : "") +
        " " +
        (numseconds > 0 && numhours === 0
          ? numseconds + " seconde" + (numseconds > 1 ? "s" : "")
          : "") +
        " "
      );
    },
  },
};
