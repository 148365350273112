<template>
	<div class="o-user-contacts container">
		<h2 class="o-user-infos__title title" v-if="interface.title">{{ interface.title }}</h2>
		<div class="o-user-contacts__filter" v-if="checkup && checkup.length > 0">
			<checkup mod="details" type="persons" :data="checkup" ></checkup>
		</div>

		<div class="o-user-contacts__filter">
			<form id="contacts_search" @submit.prevent="search">
				<button type="submit" class="button--search"></button>
				<input type="text" placeholder="Rechercher par nom/prénom" name="search" v-model="params.search"/>
			</form>
			<div class="o-user-contacts__filter--action" v-if="hasRole(user,'company')">
        <div class="o-user-contacts__filter--actives">
          <div class="field-select" v-if="!params.search.length">
            <div class="field-wrap">
              <label>Filtrer</label>
              <select v-model="params.active" @change="search">
                <option :value="true">actifs</option>
                <option :value="false">inactifs</option>
              </select>
            </div>
          </div>
        </div>
        <div class="o-user-contacts__filter--buttons">
          <button class="button button--secondary" @click="addContact">Ajouter un collaborateur</button>
          <button class="button button--border" data-icon="parameters" @click="editPermissions" v-if="permissions">Gérer les droits</button>
        </div>
      </div>
		</div>
		<div class="o-user-contacts__grid" v-infinite-scroll.full="loadMore">
			<contact class="o-user-contacts__item" v-for="(contact, index) in contacts" :key="contact.id" :data="contact"/>
		</div>
		<loader static="1" v-if="loading"></loader>
	</div>
</template>

<script>
	import companyRepository from "@/repositories/companyRepository";

	export default {
		name: "user-contacts",
		props: ['data','interface'],
		data(){
			return{
				contacts: [],
				loading: false,
				permissions: parseInt(process.env.VUE_APP_PERMISSIONS),
				count: -1,
				params:{
					limit : 12,
					offset: 0,
					search: '',
          active: true
				},
			}
		},
		computed:{
			user(){
				return this.$user()
			},
			checkup(){
				return this.$store.getters.checkup()
			}
		},
		methods:{
			editPermissions(){
				this.$popin('edit-permissions');
			},
			addContact(){
				this.$popin('contact-add');
			},
			loadMore(){

				if(this.contacts.length >= this.count || !this.contacts.length)
					return false;

				this.query(this.params.offset + this.params.limit)
			},
			search(){

				this.contacts = []
				this.count = 0

				this.query(0);
			},
			query(offset){

				if( this.loading )
					return;

				this.loading = true;
				this.params.offset = offset;

				companyRepository.getStaff(this.params).then(response=>{

					this.count = response.count;
					this.contacts = response.items;

					this.loading = false;
				})
			}
		},
		mounted() {

			this.query(0);
			this.$update(this.query);
		}
	}
</script>

<style lang="scss">
	@import '../../environment';
	.o-user-contacts{
		$c: &;
		text-align: left;

		& > .title{
			margin: $space $space $space-m;
			@media #{$to-tablet}{ margin-left: $space-m; margin-right: $space-m }
		}

		&__filter{
			background: #fff; border-radius: 4px; padding: $space; margin-bottom: $space-m; box-shadow: $subtle-box-shadow; position: relative;
			form { position: relative }
			&--action{
				display: flex; margin-top: $half-space; justify-content: space-between
			}
      &--actives{
        label{ margin-right: 1rem }
        .field-wrap{ display: flex; align-items: center }
      }
		}

		&__actives{
			display: flex; justify-content: flex-end; margin-bottom: 0; margin-top: -$space-m;
			& + #{$c}__filter{ margin-top: $space-s }
			#{$c}__filter + &{ margin-top: $space-l }
		}

		&__grid{
      display: grid;
      grid-gap: 3rem;
      @media #{$only-tablet}{ grid-template-columns: repeat(2, 1fr) }
      @media #{$from-small}{ grid-template-columns: repeat(3, 1fr) }
		}

		&__item{
			 background: white; border-radius: $border-radius; overflow: hidden; width: 100%; box-shadow: $subtle-box-shadow;
		}
	}

</style>
