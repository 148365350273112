import Vue from "vue";
import cache from "@/services/cache";
import { saveAs } from 'cf-file-saver.js';
import sha1 from 'js-sha1';

export default {

	storeProgress(id, params) {

		return new Promise((resolve, reject) => {

			let data = {chapter:params.chapter.current, subchapter:params.subchapter.current, timeElapsed:params.timeElapsed, scroll:params.scroll, media:params.media};
			data.key = sha1(JSON.stringify(data));
			Vue.http.post('formation/course/'+id+'/foad', data).then(response => {

				resolve(response.body.response);

			}, reject)
		});
	},

	list(params) {

		if( 'search' in params && params.search.length )
			return cache.list('formation/course', params, 0);
		else
			return cache.list('formation/course', params);
	},

	get(id) {

		return new Promise((resolve, reject) => {

			Vue.http.get('formation/course/'+id).then(response => {

				resolve(response.body.response)

			}, response=>{

				reject(response);
			})
		});
	},

	getDocuments(id) {

		return new Promise((resolve, reject) => {

			Vue.http.get('formation/course/'+id+'/documents').then(response => {

				resolve(response.body.response)

			}, response=>{

				reject(response);
			})
		});
	},

	positionningFormat(key) {

		return new Promise((resolve, reject) => {

			Vue.http.get('formation/getformatfromkey?key='+key).then(response => {

				resolve(response.body.response)

			}, response=>{

				reject(response);
			})
		});
	},

	getOrder(id) {

		return new Promise((resolve, reject) => {

			Vue.http.get('formation/course/'+id+'/order').then(response => {

				resolve(response.body.response)

			}, response=>{

				reject(response);
			})
		});
	},

	getSubscribed(params) {

		return cache.list('formation/course/subscribed', params);
	},

	getReport(params) {

		if( 'search' in params && params.search.length )
			return cache.list('formation/course/report', params, 0);
		else
			return cache.list('formation/course/report', params);
	},

	getContactReport(id) {
		return new Promise((resolve, reject) => {

			Vue.http.get('formation/course/contact/' + id + '/report').then(response => {

				resolve(response.body.response);

			}, reject)
		});
	},

	downloadICal() {

		return new Promise((resolve, reject) => {

			Vue.http.get('formation/course/subscribed/ical', {responseType: 'arraybuffer'}).then(response => {

				let blob = new Blob([response.data], {type: response.headers.get('Content-Type')});
				saveAs(blob, response.headers.get('Content-Disposition').split("filename=")[1])

				resolve();

			}, response=>{

				reject(response);
			});
		});
	},

	downloadAll(contact_id) {

		return new Promise((resolve, reject) => {

			Vue.http.get('formation/course/contact/'+contact_id+'/download').then(response => {

				document.location.href = response.body.response.url+'?disposition=attachment';

				resolve();

			}, response=>{

				reject(response);
			});
		});
	},

	getStatus(id, email) {

		return new Promise((resolve, reject) => {

			Vue.http.get('formation/course/'+id+'/status', {params:{email:email}}).then(response => {

				resolve(response.body.response);

			}, reject)
		});
	},

	search(type, id) {

		return new Promise((resolve, reject) => {

			Vue.http.get('formation/course/search', {params:{type:type, id:id}}).then(response => {

				resolve(response.body.response);

			}, reject)
		});
	},

	alert(id) {

		return new Promise((resolve, reject) => {

			Vue.http.post('formation/course/'+id+'/alert').then(response => {

				resolve(response.body.response);

			}, reject)
		});
	},

	cancel(id) {

		return new Promise((resolve, reject) => {

			Vue.http.post('formation/course/'+id+'/cancel').then(response => {

				resolve(response.body.response);

			}, reject)
		});
	},

	foad(id){
		return new Promise((resolve, reject) => {

			Vue.http.get('formation/course/' + id + '/foad').then(response => {

				resolve(response.body.response);

			}, reject)
		});
	},

	foadHash(id){
		return new Promise((resolve, reject) => {

			Vue.http.get('formation/course/' + id + '/foad/hash').then(response => {

				resolve(response.body.response);

			}, reject)
		});
	},
	checkNote(id){
		return new Promise((resolve, reject) => {

			Vue.http.get('formation/check-note/' + id ).then(response => {
				console.log(response)

				resolve(response.body.response);

			}, reject)
		});
	},
	getNoteTerminate(id){
		return new Promise((resolve, reject) => {

			Vue.http.post('formation/participant/' + id + '/terminate').then(response => {

				resolve(response.body.response);

			}, reject)
		});
	},

	getPicture(id){
		return new Promise((resolve, reject) => {

			Vue.http.get('formation/' + id + '/picture').then(response => {

				resolve(response.body.response);

			}, reject)
		});
	},

	getELearningLink(formation_course_id){
		return new Promise((resolve, reject)=>{
			Vue.http.get(`elearning/link?formation_course_id=${formation_course_id}`).then((response)=>{
				resolve(response.body.response)
			}, reject)
		})
	}
};
