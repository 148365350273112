var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (
    _vm.data &&
    _vm.data.status === 'confirmed' &&
    (_vm.current_time < _vm.data.startAt || _vm.data.format === 'e-learning')
  )?_c('div',{staticClass:"o-cart block",class:{ 'block--notitle': !_vm.data.title }},[(_vm.data.title)?_c('h2',{staticClass:"title"},[_vm._v(_vm._s(_vm.data.title))]):_vm._e(),_c('div',{staticClass:"equal-padding"},[(_vm.data.formation.price)?_c('div',{staticClass:"o-cart__price"},[_c('div',{staticClass:"subtitle"},[_vm._v("Prix par personne")]),_c('p',[_c('b',[_vm._v(_vm._s(_vm.formatPrice(_vm.data.formation.price))+" HT")])])]):(_vm.data.format === 'e-learning')?_c('div',{staticClass:"o-cart__price"},[_c('div',{staticClass:"subtitle"},[_vm._v("Formation en ligne")])]):_vm._e(),_c('div',[(_vm.data.format !== 'e-learning' && _vm.data.registerUntil)?_c('div',[_c('div',{staticClass:"subtitle"},[_vm._v("Date limite de souscription")]),_c('div',{staticClass:"primary"},[_vm._v(_vm._s(_vm.formatDate(_vm.data.registerUntil)))])]):_vm._e(),(
          _vm.user.isLegalRepresentative ||
          _vm.user.isCommercialAgent ||
          _vm.user.isStudent ||
          _vm.checkPermission('ROLE_FORMATION_SUBSCRIBE_WRITE', _vm.user, false)
        )?_c('div',[_c('div',{staticClass:"o-cart__participants"},[(_vm.data.remainingPlaces > 0 || _vm.data.format === 'e-learning')?_c('div',[(_vm.user.isLegalRepresentative)?_c('div',[_vm._l((_vm.cart.contacts),function(contact,index){return _c('div',{key:index,staticClass:"o-cart__item"},[_c('div',{staticClass:"field-select"},[_c('div',{staticClass:"field-wrap"},[_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.cart.contacts[index]),expression:"cart.contacts[index]"}],on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.cart.contacts, index, $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option',{attrs:{"value":"","disabled":"","selected":""}},[_vm._v(" Sélectionner un collaborateur ")]),(
                          (_vm.cart.contacts.length <= _vm.data.remainingPlaces &&
                            _vm.user.staff.length <= _vm.data.remainingPlaces) ||
                          _vm.data.format === 'e-learning'
                        )?_c('option',{attrs:{"value":"all"}},[_vm._v(" ➜ Ajouter tous les collaborateurs ")]):_vm._e(),(
                          _vm.cart.contacts.length <= _vm.data.remainingPlaces ||
                          _vm.data.format === 'e-learning'
                        )?_c('option',{attrs:{"disabled":""}}):_vm._e(),_vm._l((_vm.user.staff),function(employee){return (
                          _vm.cart.contacts.indexOf(employee.id) === -1 ||
                          _vm.cart.contacts[index] === employee.id
                        )?_c('option',{attrs:{"disabled":_vm.isDisabled(employee),"title":_vm.getTitle(employee)},domProps:{"value":employee.id}},[_vm._v(" "+_vm._s((_vm.data.participants.indexOf(employee.id) > -1 ? "✔ " : _vm.isDisabled(employee) ? "⚠ " : "") + employee.firstname + " " + employee.lastname)+" ")]):_vm._e()})],2)])]),(_vm.cart.contacts.length > 1)?_c('div',{staticClass:"o-cart__remove icon-flex",attrs:{"data-icon":"close"},on:{"click":function($event){return _vm.deleteParticipant(contact)}}}):_vm._e()])}),(
                  (_vm.cart.contacts.length <= _vm.data.remainingPlaces ||
                    _vm.data.format === 'e-learning') &&
                  this.cart.contacts.length < _vm.validEmployees().length
                )?_c('div',{staticClass:"o-cart__add",on:{"click":function($event){return _vm.addParticipant()}}},[_vm._v(" + ajouter un autre collaborateur ")]):_vm._e()],2):(_vm.data.participants.indexOf(_vm.user.contact.id) > -1)?_c('div',{staticClass:"error"},[_vm._v(" Vous avez déja participé à cette formation ")]):_vm._e()]):(
              _vm.checkPermission('ROLE_FORMATION_ALERT_AVAILABLE', _vm.user)
            )?_c('div',[_c('p',{staticClass:"error"},[_vm._v(" Nous sommes désolés, il ne reste aucune place disponible pour cette formation. ")]),_c('div',{staticClass:"bottom-right"},[(_vm.data.alert || _vm.alerted)?_c('button',{staticClass:"button button--success"},[_vm._v(" Alerté si une place se libère ")]):(_vm.user.contact)?_c('button',{staticClass:"button button--secondary",on:{"click":_vm.popinAlert}},[_vm._v(" Etre alerté si une place se libère ")]):_vm._e()])]):_vm._e()]),(_vm.subtotal)?_c('div',{staticClass:"o-cart__line"},[_c('span',[_vm._v("Sous total HT")]),_c('span',[_vm._v(_vm._s(_vm.formatPrice(_vm.subtotal)))])]):_vm._e(),(_vm.tva && _vm.total)?_c('div',{staticClass:"o-cart__line"},[_c('span',[_vm._v("TVA ("+_vm._s(_vm.data.taxRate * 100)+"%)")]),_c('span',[_vm._v(_vm._s(_vm.formatPrice(_vm.tva)))])]):_vm._e(),(_vm.total !== false)?_c('div',{staticClass:"o-cart__line o-cart__line--total"},[_c('span',[_vm._v("TOTAL TTC")]),(_vm.total)?_c('span',[_vm._v(_vm._s(_vm.formatPrice(_vm.total, true)))]):_c('span',[_vm._v("Gratuit")])]):_vm._e(),(
            _vm.config.option.cgu_vhsbs &&
            (_vm.data.remainingPlaces > 0 || _vm.data.format === 'e-learning')
          )?_c('div',{staticClass:"o-cart__cgu"},[_c('label',{staticClass:"input--checkbox text"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.cgu),expression:"cgu"}],attrs:{"type":"checkbox","name":"cgu"},domProps:{"checked":Array.isArray(_vm.cgu)?_vm._i(_vm.cgu,null)>-1:(_vm.cgu)},on:{"change":function($event){var $$a=_vm.cgu,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.cgu=$$a.concat([$$v]))}else{$$i>-1&&(_vm.cgu=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.cgu=$$c}}}}),_c('span',{staticClass:"checkmark",attrs:{"data-icon":"check"}}),_c('span',{staticClass:"o-cart__cgu-label"},[_vm._v(" Avant d'accéder au paiement, je reconnais prendre connaissance et enregistrer sur un support durable les "),_c('a',{attrs:{"href":_vm.config.option.cgu_vhsbs.value,"target":"_blank"}},[_vm._v("Conditions Générales d'Utilisation")]),_vm._v(", et accepte que la présente formation n'est pas remboursable en cas d'annulation. ")])])]):_vm._e(),(_vm.data.remainingPlaces > 0 || _vm.data.format === 'e-learning')?_c('div',{staticClass:"bottom-right"},[_c('form',{attrs:{"id":"formation_subscribe"},on:{"submit":function($event){$event.preventDefault();return _vm.subscribe.apply(null, arguments)}}},[_c('submit',{attrs:{"disabled":_vm.cannotOrder,"text":"Souscrire","loading":_vm.loading}})],1)]):_vm._e()]):(_vm.checkPermission('ROLE_FORMATION_ALERT_INTEREST', _vm.user))?_c('div',{staticClass:"o-cart__interest"},[_vm._m(0),_c('div',{staticClass:"bottom-right"},[(_vm.data.alert || _vm.alerted)?_c('button',{staticClass:"button button--success"},[_vm._v(" Votre représentant légal a été alerté ")]):(_vm.user.contact)?_c('button',{staticClass:"button button--secondary",on:{"click":_vm.popinInterest}},[_vm._v(" Cette formation m'intéresse ")]):_vm._e()])]):_vm._e()])])]):_vm._e()}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('p',{staticClass:"error"},[_vm._v(" En tant que collaborateur, vous ne pouvez pas souscrire à une formation. "),_c('br'),_vm._v(" Toutefois, nous vous invitons à signaler votre intérêt pour cette formation à votre représentant légal ! ")])}]

export { render, staticRenderFns }