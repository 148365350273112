<template>

	<main class="v-page">
		<component :is="item.component" :data="formation" :interface="item.data" v-for="(item, index) in page.components" :key="index" />
		<loader v-if="loading" static="1" tall="1"></loader>
    <h2 class="title" v-else-if="!formation">La formation n'existe pas.</h2>
	</main>

</template>

<script>
	import Interface from '@/interface.yml';
	import formationCourseRepository from "@/repositories/formationCourseRepository";

	export default {
		data() {
			return {
				json: Interface,
				page: false,
        formation: false,
				loading: true
			}
		},
    methods:{
      getPicture(formation_id){
        // search image from cms
        return formationCourseRepository.getPicture(formation_id).then((response) => {
          return response.image_url;
        });
      }
    },
		mounted(){
			this.page = this.json.pages[this.$route.name];

      formationCourseRepository.get(this.$route.params.id).then(response=>{
	      let formation = response;
        formation.taxRate = this.$user().tva;
        Promise.resolve(this.getPicture(formation.formation.id)).then(response=>{
          formation.formation.picture_url = response;
          this.formation = formation;
          this.loading = false;
        }, response=>{
          this.formation = formation;
          this.loading = false;
        });

			}, response=>{
        this.loading = false;
      });

		}
	}
</script>
